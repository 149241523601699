import { createFileRoute, redirect } from "@tanstack/react-router"
import { getAuthCookie } from "@src/utils/cookies"

export const Route = createFileRoute("/_auth")({
  beforeLoad: async ({ location, context }) => {
    // Maybe move this to the component and use the useAuth hook?
    // Is there any advantage to doing it here?
    const { useAppStore } = context
    const token = useAppStore.getState().token

    if (!token) {
      const authCookie = getAuthCookie()

      if (authCookie) {
        useAppStore.setState({ token: authCookie })
        return
      }

      throw redirect({
        to: "/login",
        search: {
          // Use the current location to power a redirect after login
          // (Do not use `router.state.resolvedLocation` as it can
          // potentially lag behind the actual current location)
          redirect: location.href,
        },
      })
    }
  },
})

// Add an interceptor to the client that if a request fails with a 401
// it sets the token to null and this is caught in this route and redirects
// to the login page
