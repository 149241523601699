import { Outlet } from "@tanstack/react-router"
import { Header } from "@src/components/organisms/Header"

export const CommunityLayout = () => {
  return (
    <div className="min-h-dvh w-full">
      <Header />
      <div className="flex min-h-[calc(100dvh-84px)]">
        <div className="basis-[270px] border-r-[1px] border-gray-300 p-8">
          Navigation menu goes here
        </div>
        <main className="p-8">
          <Outlet />
        </main>
      </div>
    </div>
  )
}
