import { UserCalendarType, MeetingProviderType } from "./calendar"
import { Community, CommunityForUserProfile } from "./community"
import { Country } from "./country"
import { client } from "./client"
import { queryOptions } from "@tanstack/react-query"

export type Interest = {
  id: number
  name: string | null
  popUpText: string | null
  isActive: boolean
  isAddedByUser: boolean
}

export type SkillCategory = {
  id: number
  name: string | null
  isActive: boolean
  skills: Skill[] | null
}

export type Skill = {
  id: number
  name: string | null
  isActive: boolean
  isAddedByUser: boolean
  skillCategoryId: number
  skillCategory: SkillCategory | null
}

export type UserWorkingExperience = {
  id: number
  userId: number
  countryId: number
  countryName: string | null
  city: string | null
  title: string | null
  description: string | null
  employer: string | null
  startDate: string | null
  endDate: string | null
}

export type SustainableDevelopmentGoal = {
  id: number
  name: string | null
  iconName: string | null
}

export type CurrentUser = {
  id: number
  firstName: string | null
  lastName: string | null
  mail: string | null
  isPlatformAdmin: boolean
  occupation: string | null
  biography: string | null
  commentCount: number
  likeCount: number
  followedUsersCount: number
  activationCode: string | null
  isProfileWizardDone: boolean
  countryId: number | null
  country: Country
  city: string | null
  company: string | null
  isBanned: boolean
  isActive: boolean
  passwordForgottenCode: string | null
  passwordForgottenDate: string | null
  isSubscribedToExternalMail: boolean
  invitationUrl: string | null
  language: string | null
  registrationDate: string | null
  notificationEnabled: boolean
  redirectAfterFirstLogin: string | null
  primaryCommunity: Community | null
  primaryCommunityId: number | null
  shouldBeRedirectedOnLogOn: boolean
  name: string | null
  photoUrl: string | null
  isOwnerOrFollower: boolean | null
  canViewDescussionList: boolean
  canViewUserList: boolean
  isUserSDGsVisible: boolean | null
  isCalendarSynchronized: boolean
  synchronizedCalendarAccount: string | null
  synchronizedCalendarType?: UserCalendarType | null
  hasMeetingProvider: boolean
  meetingProviderAccount: string | null
  meetingProviderType?: MeetingProviderType | null
  interests: Interest[] | null
  marketExpertises: Country[] | null
  sustainableDevelopmentGoals: SustainableDevelopmentGoal[] | null
  pendingRequestIds: number[] | null
}

export type GetTokenRequest = {
  password: string
  username: string
  recaptchaResponse?: string
}

export type GetTokenRequestWithGrant = GetTokenRequest & {
  grant_type: string
}

export type TokenResponse = {
  access_token: string
  username: string
  token_type: string
}

export const getCurrentUser = async () => {
  const response = await client.get<CurrentUser>("/api/GetCurrent")
  return response.data
}

export const getCurrentUserQuery = () =>
  queryOptions({
    queryKey: ["users", "current"],
    queryFn: getCurrentUser,
  })

export const getCurrentUserCommunities = async () => {
  const response = await client.get<CommunityForUserProfile[]>(
    "/api/User/GetMyCommunities",
  )
  return response.data
}

export const getCurrentUserCommunitiesQuery = () =>
  queryOptions({
    queryKey: ["users", "current", "communities"],
    queryFn: getCurrentUserCommunities,
  })

export const getToken = async (
  request: GetTokenRequest,
): Promise<TokenResponse> => {
  const data: GetTokenRequestWithGrant = {
    grant_type: "password",
    ...request,
  }

  const response = await client.request<TokenResponse>({
    url: "/token",
    data,
    method: "POST",
  })

  return response.data
}

// export const getTokenQuery = queryOptions({
//   queryKey: ["users", "token"],
//   queryFn: () => getToken,
// })
