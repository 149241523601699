import axios from "axios"
import { useAppStore } from "../state"

// TODO: maybe create a separate client for unauthenticated
// requests, so we don't attach the token to them
export const client = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
})

client.interceptors.request.use((config) => {
  const token = useAppStore.getState().token

  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`
  }

  return config
})
